.font-body {
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 1920px) {
  .home-card > div {
    width: auto !important;
  }
}
